import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const TermsOfUsePage = ({ data }) => (
  <Layout>
    <SEO title="Privacy Page" keywords={configs.app_keywords} />

    <div className="headerBackground">
      <div className="privacy-container">
        <header>
            <div className="logo">
              <div className="appIconShadow">
                <svg width="0" height="0">
                  <defs>
                    <clipPath id="shape">
                      <path
                        id="shape"
                        d="M6181.23,233.709v-1.792c0-.5-0.02-1-0.02-1.523a24.257,24.257,0,0,0-.28-3.3,11.207,11.207,0,0,0-1.04-3.132,10.683,10.683,0,0,0-1.95-2.679,10.384,10.384,0,0,0-2.68-1.943,10.806,10.806,0,0,0-3.13-1.038,19.588,19.588,0,0,0-3.3-.285c-0.5-.017-1-0.017-1.52-0.017h-22.39c-0.51,0-1.01.017-1.53,0.017a24.041,24.041,0,0,0-3.3.285,11.009,11.009,0,0,0-3.13,1.038,10.491,10.491,0,0,0-4.62,4.622,10.893,10.893,0,0,0-1.04,3.132,19.2,19.2,0,0,0-.28,3.3c-0.02.5-.02,1-0.02,1.523v22.392c0,0.5.02,1,.02,1.524a24.257,24.257,0,0,0,.28,3.3,10.9,10.9,0,0,0,1.04,3.132,10.491,10.491,0,0,0,4.62,4.622,11.04,11.04,0,0,0,3.13,1.038,19.891,19.891,0,0,0,3.3.285c0.51,0.017,1.01.017,1.53,0.017h22.39c0.5,0,1-.017,1.52-0.017a24.221,24.221,0,0,0,3.3-.285,10.836,10.836,0,0,0,3.13-1.038,10.408,10.408,0,0,0,2.68-1.943,10.683,10.683,0,0,0,1.95-2.679,11.217,11.217,0,0,0,1.04-3.132,20.257,20.257,0,0,0,.28-3.3c0.02-.5.02-1,0.02-1.524v-20.6h0Z"
                        transform="translate(-6131 -218)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Img
                  fluid={data.headerIcon.childImageSharp.fluid}
                  className="headerIcon"
                />
              </div>
              <p className="headerName">{configs.app_name}</p>
            </div>
            
              <nav>
                <ul>
                {configs.presskit_download_link && (
                  <li>
                    <a href={configs.presskit_download_link}>Press Kit</a>
                  </li>
                  )}
                  <li>
                    <a href="/">Home</a>
                </li>
                </ul>
              </nav>
          </header>
          
          <div className="privacy-content">
          <h1>Terms of Use</h1>
          <br></br>
          <h2>Payment &#38; Unlocking Pro Features </h2>
          <br></br>
          <p>
              You can subscribe for <strong>PRO Access</strong> in this app to get access to premium features and unlimited content.
            </p>
          <p>
              Subscription options can be: 1-month with 3-day trial, 6-months or 1-year.
            </p>
          <p>
              All prices are subject to change without notification. We occasionally run promotion prices as incentives or limited time opportunities for qualifying purchases made during the promotional time period. Due to the time sensitive and promotional nature of these events, we are unable to offer price protection or retroactive discounts or refunds for previous purchases in the event of a price reduction or promotional offering.
            </p>
          <p>
              Payment will be charged to iTunes Account at confirmation of purchase.
            </p>
          <p>

            </p>
          <p>
              Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.
            </p>
          <p>
              Account will be charged for renewal within 24-hours prior to the end of the current period at the cost of the chosen package.
            </p>
          <p>
              Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user's iTunes Account Settings after purchase.
            </p>
          <p>
              No cancellation of the current subscription is allowed during active subscription period.
            </p>
          <p>
              You may cancel a subscription during its free trial period via the subscription setting through your iTunes account.
              This must be done 24 hours before the end of the subscription period to avoid being charged.
              Please visit https://support.apple.com/en-us/HT202039 for more information.
            </p>
          <p>
              You may turn off the auto-renewal of your subscription via your iTunes Account Settings. However, you are not able to cancel the current subscription during its active period.
            </p>
          <p>
              Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.
            </p>


            <br></br>

            <h2>Renewals &#38; Cancellations </h2>
            <br></br>

            <p>
              Your subscription is automatically renewed at the end of the subscription period. Certain subscription offerings may offer a free trial prior to charging your payment method, the period of which will be communicated to you via the Service. If you do not want your card to be charged you must turn off auto-renew at least 24-hours before the end of the free trial period. Otherwise, you will be responsible for payment for the full term of the subscription period.
            </p>

            <p>
            You can modify or cancel your subscription at any time by signing in to your iTunes account on your computer or iOS or Android device and changing the settings. For more details please visit: http://support.apple.com/kb/HT4098
            </p>

            <h3>Lifetime Plans</h3> 
            <br></br>

            <p>
            A  lifetime plan allows you to access the app’s premium features available at the time or your purchase for as long as those services are available. You may not assign lifetime plans to any other person. We make no warranties or representations as to the expected lifetime of the Guiding Ring’s services, and in purchasing a lifetime plan, you acknowledge and agree that the Guiding Ring’s services could change or terminate in the future.
            </p>

            
            <h3>Refunds</h3>
            <br></br>

            <p>
            Your payment is managed by Apple via iTunes and you will need to contact Apple customer service for refunds, as we are not able to process refunds ourselves. 
            </p>

            <h3>Pricing changes</h3>
            <br></br>
            
            <p>
            We may change the pricing plan at our discretion. In this case we will try to inform you before any changes so you can modify or cancel your subscription before the changes are made. We accept no responsibility for any losses you may incur by failing to change your subscription after we have informed you of any changes to the pricing plan.
            </p>


          </div>
  

          <footer>
            <p className="footerText">
              Made with ❤️ by{" "}
              {configs.your_link ? (
                <a href={configs.your_link}>{configs.your_name}</a>
              ) : (
                `${configs.your_name}`
              )}
              {configs.your_city && ` in ${configs.your_city}`}
            </p>
            <div className="footerIcons">
              {configs.facebook_username && (
                <a
                  href={`https://facebook.com/${configs.facebook_username}`}
                  aria-label="Facebook"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-facebook fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.linkedin_username && (
                <a
                  href={`https://www.linkedin.com/in/${configs.linkedin_username}`}
                  aria-label="LinkedIn"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-linkedin fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.twitter_username && (
                <a
                  href={`https://twitter.com/${configs.twitter_username}`}
                  aria-label="Twitter"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-twitter fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.github_username && (
                <a
                  href={`https://github.com/${configs.github_username}`}
                  aria-label="GitHub"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-github fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.email_address && (
                <a href={`mailto:${configs.email_address}`} aria-label="Email">
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fas fa-envelope fa-stack-1x" />
                  </span>
                </a>
              )}
            </div>
          </footer>
      </div>
    </div>

  </Layout>
)

export default TermsOfUsePage

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appStore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appIconLarge: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headerimage.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
